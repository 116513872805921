html, body {
    height: 100%;
    min-height: 100vh;
}

#__next {
    height: 100%;
}

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    color: #3f3f3f;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.75;
}

hr {
    border-color: var(--color-gray--30);
    border-style: solid;
}

/* start custom styles */

:root {
    --spacing-small: 8px;
    --color-red: #E2231A;
}

.centered {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
}

@media (max-width: 32.5rem) {
    .centered {
        margin-top: 0;
    }
}

sub,
sup {
    position: relative;
    font-size: 75% !important;
    line-height: 0;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.5em;
}


